<template lang="pug">
section.main
  aside.side-menu(v-if="data && interfaceStore.sidemenu")
    Sidemenu
  main.core
    //- active-team
    section.section(v-bind:class="{ 'padless': interfaceStore.padless }")
      slot
  //- aside.side-menu(v-if="$auth.loggedIn")
  //-   main-menu
  //- main.core
  //-   nuxt
</template>

<script setup>
import { useInterfaceStore } from "~/stores/interface";
const interfaceStore = useInterfaceStore();
import { useTeamStore } from "~/stores/team";
const teamStore = useTeamStore();
await teamStore.getTeams();
await teamStore.getActiveTeam();
// await teamStore.getTeamInvites();
const { signIn, token, signOut, data, status, lastRefreshedAt } = useAuth();
watch(
  () => token.value,
  async (token) => {
    if (!token) {
      await signOut({ callbackUrl: "/login" });
    }
  }
);
</script>
<style lang="scss">
section.main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  .padless {
    padding: 0;
  }
  .side-menu {
    // flex: 1;
    width: rempx(91);
  }
  .core {
    flex: 1;
  }
}
</style>
